import styled from 'styled-components';

export const Dl = styled.dl``;

export const Dt = styled.dt`
  font-weight: 600;
  margin-bottom: 0.2em;
`;

export const Dd = styled.dd`
  margin-bottom: 1em;
  margin-left: 1.5em;
`;
